@tailwind base;
@tailwind components;
@tailwind utilities;

.energy-flow-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  --g: 9vw;
  column-gap: var(--g);
  row-gap: calc(var(--g) / 2);
}

.energy-flow-grid > * {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  position: relative;
  width: 100%;
}

.energy-flow-grid > *::before {
  content: '';
  height: calc(var(--g) / 2);
  width: 2px;
  background-image: linear-gradient(lightgray 0 50%, lightgray 50% 100%);
  background-size: auto calc(var(--g) / 4);
  position: absolute;
  bottom: 100%;
  left: 50%;
}

.energy-flow-grid > *:nth-child(1)::before,
.energy-flow-grid > *:nth-child(2)::before,
.energy-flow-grid > *:nth-child(3)::before,
.energy-flow-grid > *:nth-child(4)::before {
  display: none;
}

.energy-flow-grid > *::after {
  content: '';
  width: var(--g);
  height: 2px;
  background-image: linear-gradient(
    to right,
    lightgray 0 50%,
    lightgray 50% 100%
  );
  background-size: calc(var(--g) / 4);
  position: absolute;
  top: 50%;
  left: 100%;
}

.energy-flow-grid > *:nth-child(4n)::after,
.energy-flow-grid > *:last-child::after {
  display: none;
}
